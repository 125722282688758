import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BackgroundSection from "../components/Globals/BackgroundSection"
import Info from "../components/Home/Info"
import HomeMenu from "../components/Menu/HomeMenu"

const IndexPage = ({ data }) => {
  const image = getImage(data.image.childImageSharp.gatsbyImageData)
  const menuImage = getImage(data.menuImg.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Seo title="The Crab Station & Oyster Bar" />
      <BackgroundSection
        img={image}
        title="Crab Station & Oyster Bar Odessa TX"
        description="Voted Texas' Best Seafood Restaurant"
        styleClass="default-background background-images"
      />
      <Info />
      <HomeMenu
        img={menuImage}
        title="Menus"
        styleClass="homeMenu-background background-images"
      />
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "background5.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          quality: 50
          placeholder: NONE
          blurredOptions: { width: 100 }
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }
    menuImg: file(relativePath: { eq: "background4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1500
          quality: 50
          placeholder: NONE
          blurredOptions: { width: 100 }
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }
  }
`

export default IndexPage
