import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

import Title from "../Globals/Title"

export default function HomeMenu({ img, styleClass, title, children }) {
  const bgImage = convertToBgImage(img)
  return (
    <BackgroundImage
      Tag="section"
      className={styleClass}
      {...bgImage}
      preserveStackingContext={true}
      style={{
        // backgroundPosition: "center 10rem",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="row d-flex">
        <div className="col-11 col-md-6 d-flex mx-auto my-2">
          {/* title */}
          <div className="mx-auto py-2 text-center">
            <Title title="MENUS" />
            <p className="home-menu-section-p pt-4">
              <small>
                Discover the delicious offerings we have every day of the week.
              </small>
            </p>
          </div>
        </div>
        <div className="hero-buttons">
          <Link to="/menu/">
            <button className="btn btn-brand text-uppercase ">
              DISCOVER OUR MENU
            </button>
          </Link>
        </div>
      </div>
      {children}
    </BackgroundImage>
  )
}
