import React from "react"
import { Link } from "gatsby"

import Title from "../Globals/Title"

export default function Info() {
  return (
    <section className="py-5 containerzzz">
      <div className="container hero-text-xred">
        <Title title="OUR STORY" />
        <div className="row">
          <div className="col-md-10 col-sm-6 col-xs-8 mx-auto text-center">
            <p className="lead hero-text-xred  mb-5">
              Crab Station & Oyster Bar in Odessa, Texas est. 2020
            </p>
            <div className="lead hero-text-xred col-10 mx-auto font-weight-bold">
              <div>
                <p className="about-p text-justify">
                  <small>
                    The Crab Station strives to provide the best seafood in a
                    family oriented experience. Have fun cracking and peeling
                    seafood with your own hands and getting messy while you're
                    at it.
                  </small>
                </p>
              </div>
              <div>
                <p className="about-p">
                  <small>
                    We have always had the love for food and what is better than
                    sharing great food with the ones you love, your family. We
                    hope that you enjoy our food and the experience at our
                    restaurant as we share a part of our family tradition with
                    you.
                  </small>
                </p>
              </div>
            </div>
            <div className="hero-buttons">
              <Link to="/about/">
                <button className="btn btn-brand text-uppercase">
                  About Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
